
import Hero from "./Hero";
import Manage from "./Manage";
import Strip from "./Strip";
import Tab from "./Tab"
import Products from "./Products";
import Starbucks from "./Starbucks";
import Story from "./Story";
import Deploys from "./Deploys";
import Resources from "./Resources";
import Business from "./Business";
const Iot = () => {

  return (
    <>
<Hero/>
<Manage/>
<Strip/>
<Products/>
<Tab/>
<Starbucks/>
<Story/>
<Deploys/>
<Resources/>
<Business/>
    </>)
}
export default Iot;