


const Strip = () => {
  
    return (
<>

<div className=" bg-blue-300 w-full   ">
<div className=" container   mx-auto py-[50px]">

<h1 className="text-center text-lg font-[350] text-[#3C3C3C] font-poppins">
Gartner® names Cisco Meraki a leader in the 2024 Magic Quadrant™ for SD-WAN br cloud and edge <br /> infrastructure. <span className="font-semibold"> Get the report </span>
</h1>
</div>
</div>
</>

)}
export default Strip;